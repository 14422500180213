import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

function ViewGroupDetail({ show, onHide, data }) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <Modal className="modal fade" show={show} size="lg">
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Details</h4>
              </div>
              {/* <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <h5>Group Details</h5>
                <ul>
                  {Object.entries(detail).map(([key, value]) => (
                    <li key={value.id}>
                      <strong>{key.replace(/_/g, " ").toUpperCase()}:</strong>{" "}
                      {value}
                    </li>
                  ))}
                  <li>
                    <strong>Created At:</strong> {formatDate(detail.created_at)}
                  </li>
                  <li>
                    <strong>Updated At:</strong> {formatDate(detail.updated_at)}
                  </li>
                </ul>
                <h5>Owner Details</h5>
                <ul>
                  {Object.entries(detail.owner_data).map(([key, value]) => (
                    <li key={value.id}>
                      <strong>{key.replace(/_/g, " ").toUpperCase()}:</strong>{" "}
                      {value}
                    </li>
                  ))}
                </ul>

                <div className="add-contact-box"></div>
              </div> */}
              <Modal.Body>
                <h5 className="mb-3">Group Details</h5>
                <div className="table-border">
                  {data &&
                    Object.entries(data).map(([key, value]) => {
                      if (
                        key === "created_at" ||
                        key === "updated_at" ||
                        key === "owner_data" ||
                        key === "backup_public_ip" ||
                        key === "billing_country_code" ||
                        key === "clear_type" ||
                        key === "country_code" ||
                        key === "finance_country_code" ||
                        key === "finance_email" ||
                        key === "finance_firstname" ||
                        key === "finance_lastname" ||
                        key === "finance_number" ||
                        key === "group_data" ||
                        key === "intergration_hotel_id" ||
                        key === "is_deleted" ||
                        key === "public_ip"
                      ) {
                        return null; // Skip rendering these fields
                      }

                      return (
                        <div
                          key={key}
                          className="my-2 d-flex align-items-center justify-content-between flex-wrap gap-1"
                        >
                          <strong className="mr-2">
                            {key.replace(/_/g, " ").toUpperCase()}:
                          </strong>{" "}
                          <span dangerouslySetInnerHTML={{ __html: value }} />
                        </div>
                      );
                    })}
                  {data?.created_at && (
                    <div className="my-2 d-flex align-items-center justify-content-between flex-wrap gap-1">
                      <strong>Created At:</strong> {formatDate(data.created_at)}
                    </div>
                  )}
                  {data?.updated_at && (
                    <div className="my-2 d-flex align-items-center justify-content-between flex-wrap gap-1">
                      <strong>Updated At:</strong> {formatDate(data.updated_at)}
                    </div>
                  )}
                </div>
                <h5 className="my-3">Owner Details</h5>
                <div className="my-2 d-flex align-items-center justify-content-between flex-wrap gap-1">
                  {data?.owner_data &&
                    Object.entries(data.owner_data).map(([key, value]) => (
                      <li
                        key={key}
                        className="my-2 d-flex align-items-center justify-content-between flex-wrap gap-1"
                      >
                        <strong className="mr-2">
                          {key.replace(/_/g, " ").toUpperCase()}:
                        </strong>{" "}
                        <span dangerouslySetInnerHTML={{ __html: value }} />
                      </li>
                    ))}
                </div>
              </Modal.Body>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewGroupDetail;
