import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
//import logo from '../../images/logo-full.png'
import { ToastContainer, toast } from "react-toastify";
import { changePasswoard } from "../../services/AuthService";
function CreateHotelAdmin({ addHotelOwnerShow, onHide }) {
  //Modal box
  let errorsObj = {
    name: "",
    lname: "",

    email: "",

    roll: "",
    password: "",
    pnumber: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [name, setName] = useState("");
  const [lname, setLname] = useState("");

  const [email, setEmail] = useState("");

  const [roll, setRoll] = useState("");
  const [password, setPassword] = useState("");
  const [pnumber, setPnumber] = useState("");
  const notifyTopRight = () => {
    toast.success("✅ Password Updated successfully !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function onAddHotelOwner(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "First Name is Required";
      error = true;
    }
    if (lname === "") {
      errorObj.lname = "Last Name is Required";
      error = true;
    }

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }

    if (roll === "") {
      errorObj.roll = "Roll is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    if (pnumber === "") {
      errorObj.pnumber = "Phone number is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    // changePasswoard(name, room, location)
    //   .then((response) => {
    //     setName("");
    //     setRoom("");
    //     setLocation("");
    //     onHide();
    //     notifyTopRight("Create Successfully.");
    //   })
    //   .catch((error) => {
    //     // setApiError(error.response.data.message);
    //     notifyError(error.response.data.message);
    //
    //   });
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" show={addHotelOwnerShow}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={onAddHotelOwner}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Creeate Hotel Admin</h4>
                {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">First Name</label>
                      <div className="contact-name">
                        <input
                          type="test"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter your Name"
                        />
                        <span className="validation-text"></span>
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Last Name</label>
                      <div className="contact-name">
                        <input
                          type="test"
                          className="form-control"
                          autocomplete="off"
                          name="lastname"
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                          placeholder="Enter your Last Name"
                        />
                        <span className="validation-text"></span>
                        {errors.lname && (
                          <div className="text-danger fs-12">
                            {errors.lname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          className="form-control"
                          autocomplete="off"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your Email"
                        />
                        <span className="validation-text"></span>
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Roll</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autocomplete="off"
                          name="roll"
                          value={roll}
                          onChange={(e) => setRoll(e.target.value)}
                          placeholder="Enter Roll"
                        />
                        <span className="validation-text"></span>
                        {errors.roll && (
                          <div className="text-danger fs-12">{errors.roll}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name">
                        <input
                          type="number"
                          className="form-control"
                          autocomplete="off"
                          name="pnumber"
                          value={pnumber}
                          onChange={(e) => setPnumber(e.target.value)}
                          placeholder="Enter Phone Number"
                          min={0}
                        />
                        <span className="validation-text"></span>
                        {errors.pnumber && (
                          <div className="text-danger fs-12">
                            {errors.pnumber}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Password</label>
                      <div className="contact-name">
                        <input
                          type="password"
                          className="form-control"
                          autocomplete="off"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                        />
                        <span className="validation-text"></span>
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  // onClick={onChangePassword}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateHotelAdmin;
