import React from "react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { forgetPassword } from "../../services/AuthService";
import { ToastContainer, toast } from "react-toastify";

export default function ({ show, onHide }) {
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState("");

  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (emailError && validateEmail(newEmail)) {
      setEmailError((prevErrors) => ({ ...prevErrors, email: "" }));
    }
    setEmailError("");
  };

  async function handleResetPassword() {
    if (email?.trim() === "") {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Email should be valid");
      return;
    }
    setLoader(true);
    try {
      const response = await forgetPassword(email);
      console.log(response);
      notifyTopRight(response?.data?.responseMessage);
    } catch (err) {
      notifyError(err?.response?.data?.responseMessage);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal.Header closeButton>
        <h4 className="text-start">Forget Password</h4>
      </Modal.Header>
      <div className="form-group mb-3 p-4">
        <p className="mb-3">
          Enter your email address, and we’ll send you a link to reset your
          password.
        </p>
        <div className="contact-name">
          <input
            type="email"
            className="form-control"
            autocomplete="off"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
          />
          <span className="text-danger">{emailError}</span>
        </div>
        <div className="pt-3 d-flex justify-content-center">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleResetPassword()}
          >
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Reset Password"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}
