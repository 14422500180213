

import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";

import CreateHotelGroup from "../modal/CreateHotelGroup";
import { deleteCategory, deleteGroup, getAllCategoryList, getAllGroup } from "../../services/AuthService";
import Delete from "../modal/Delete";
import ViewGroupDetail from "../modal/ViewGroupDetail";
import CreateHotelOwner from "../modal/CreateHotelOwner";
import CreateCategory from "../modal/CreateCategory";

export default function Category(props) {
  const [addHotelGroupShow, setAddHotelGroupShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);

  const [detail, setDetail] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [groupData, setGroupData] = useState(null); // Store the data of the group being edited
  const [searchTimer, setSearchTimer] = useState(null);
  const [addCategoryShow, setAddCategoryShow] = useState(false);
console.log(addCategoryShow, "addCategoryShow")
  const limit = 10;
  const handleEdit = (data) => {
    setGroupData(data);
    setEditMode(true);
    setAddHotelGroupShow(true);
  };

  const handleCreate = () => {
    setGroupData(null);
    setEditMode(false);
    setAddHotelGroupShow(true);
  };

  const handleModalClose = () => {
    setAddHotelGroupShow(false);
    setEditMode(false);
  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getAllCategoryList(currentPage, limit, search)
      .then((response) => {
        console.log(response, "table data");
        setUsers(response.data.result.data);
        // setImage(response.data.data.Users?.fitnessCertificate);
        setLoader(false);
        const total = response.data.result.meta.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.error(error, "error");
        setLoader(false);
      });
  }

  function onDelete() {
    setLoader(true);

    deleteCategory(id)
      .then((response) => {
        getTableData();
        setDeleteModal(false);
        setLoader(false);
        notifyTopRight(response.data.responseMessage);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.responseMessage);
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  useEffect(() => {
    if (search !== "") {
      setCurrentPage(0);
    }
  }, [search]);
  useEffect(() => {
    if (search !== "") {
      const delayedSearch = setTimeout(() => {
        setCurrentPage(0); // Reset currentPage to 0
        getTableData();
      }, 1000);

      return () => clearTimeout(delayedSearch);
    } else {
      setCurrentPage(0);
      getTableData();
    }
  }, [search]);
  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value.trim();
    setSearch(newSearchValue);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Category List" motherMenu="Category" />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setAddCategoryShow(true)}
        >
          Create
        </button>
      </div>

      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Category name </strong>
                  </th>
                 
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, i) => (
                  <tr key={i}>
                    <td>
                
                      {item.name.length > 12
                        ? item.name.slice(0, 12) + "..."
                        : item.name}
                    </td>
                  
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setId(item.id);
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                       
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-end">
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      {loader && <Spinner />}
      {addHotelGroupShow && (
        <CreateHotelGroup
          addHotelOwnerShow={addHotelGroupShow}
          onHide={handleModalClose}
          callgrouplist={getTableData}
          editMode={editMode}
          groupData={groupData}
        />
      )}

      {deleteModal && (
        <Delete
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          callbackfunction={onDelete}
        />
      )}
      {viewDetailModal && detail && (
        <ViewGroupDetail
          show={viewDetailModal}
          onHide={() => setViewDetailModal(false)}
          data={detail}
        />
      )}

{addCategoryShow && (
        <CreateCategory
        addCategoryShow={addCategoryShow}
          onHide={() => setAddCategoryShow(false)}
          hotelOwnerListCall={getTableData}
        />
      )}
    </div>
  );
}
