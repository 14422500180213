import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import Switch from "react-switch";
//import logo from '../../images/logo-full.png'
import { toast } from "react-toastify";
import { createGroup, editGroup } from "../../services/AuthService";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-number-input";

function CreateHotelGroup({
  addHotelOwnerShow,
  onHide,
  callgrouplist,
  editMode,
  groupData,
}) {
  //Modal box
  const initialFormState = {
    group_name: editMode ? groupData.group_name : "",
    headquarter_address: editMode ? groupData.headquarter_address : "",
    headquarter_suburb: editMode ? groupData.headquarter_suburb : "",
    headquarter_city: editMode ? groupData.headquarter_city : "",
    headquarter_postcode: editMode ? groupData.headquarter_postcode : "",
    headquarter_state: editMode ? groupData.headquarter_state : "",
    headquarter_country: editMode ? groupData.headquarter_country : "",
    lat: editMode ? groupData.lat : 0,
    long: editMode ? groupData.long : 0,
    contact_number: editMode ? groupData.contact_number : "",
    contact_email: editMode ? groupData.contact_email : "",
    contact_firstname: editMode ? groupData.contact_firstname : "",
    contact_lastname: editMode ? groupData.contact_lastname : "",
    finance_number: editMode ? groupData.finance_number : "",
    finance_firstname: editMode ? groupData.finance_firstname : "",
    finance_lastname: editMode ? groupData.finance_lastname : "",
    tax_number: editMode ? groupData.tax_number : "",
    notes: editMode ? groupData.notes : "",
    country_code: editMode ? groupData.country_code : "+91",
    finance_country_code: editMode ? groupData.finance_country_code : "+91",
    gst: editMode ? groupData.gst : false,
    description: editMode ? groupData.description : "",
  };

  // const [formState, setFormState] = useState(initialFormState);
  // const [formState, setFormState] = useState(initialFormState);
  const [formState, setFormState] = useState(initialFormState || {});
  const [errors, setErrors] = useState("");
  const [errorsFinace, setErrorsFinace] = useState("");

  const [requiredErrors, setRequiredErrors] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    (groupData && groupData?.contact_number) || ""
  );
  const [gst, setGst] = useState((groupData && groupData?.gst) || false);

  const [selectedFinanceCountryCode, setSelectedFinanceCountryCode] = useState(
    (groupData && groupData?.finance_number) || ""
  );
  const [googleAddress, setGoogleAddress] = useState(
    (groupData && groupData?.headquarter_address) || ""
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if (value.trim() === "") {
    //   setErrors(
    //     `${name.replace(/_/g, " ")} cannot be empty or contain only spaces.`
    //   );
    // } else {
    //   setErrors("");
    //   setFormState((prev) => ({ ...prev, [name]: value }));
    // }

    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event);
    setFormState({
      ...formState,
      contact_number: editMode ? groupData.contact_number : event,
    });
  };
  const handleFinanceCountryCodeChange = (event) => {
    setSelectedFinanceCountryCode(event);
    setFormState({
      ...formState,
      finance_number: editMode ? groupData.finance_number : event,
    });
  };
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleAddress = (place) => {
    let city = "";
    let zipCode = "";
    let suburb = "";
    let state = "";
    let country = "";
    setGoogleAddress(place.label);

    geocodeByAddress(place.label)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];

        city =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name || "";

        suburb =
          addressComponents.find((component) =>
            component.types.includes("sublocality")
          )?.long_name || "";

        state =
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "";

        country =
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "";

        // Find the zip code from address components
        zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        return getLatLng(results[0]);
      })
      .then((res) => {
        setFormState((prevLocation) => ({
          ...prevLocation,
          headquarter_address: place.label,
          headquarter_suburb: suburb,
          headquarter_city: city,
          headquarter_postcode: zipCode,
          headquarter_state: state,
          headquarter_country: country,
          lat: res.lat,
          long: res.lng,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };
  const handleGst = (nextChecked) => {
    setGst(nextChecked);
    setFormState({
      ...formState,
      gst: editMode ? groupData.gst : nextChecked,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    // Check for validation errors
    // if (Object.values(formState).some((value) => value.trim() === "")) {
    //   setRequiredErrors("All fields are required.");
    //   setLoader(false);
    //   return;
    // }

    if (editMode) {
      // Remove leading and trailing spaces before API call

      const trimmedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [
          key,
          typeof value === 'string' ? value.trim() : value,
        ])
      );      
      editGroup(trimmedFormState, groupData.id)
        .then((response) => {
          setLoader(false);
          onHide();
          notifyTopRight(response.data.responseMessage);
          callgrouplist();
        })
        .catch((error) => {
          setLoader(false);
          console.error(error.response, "error");
          // setApiError(error.response.data.message);
          notifyError(error.response?.data?.responseMessage);
        });
    } else {
      if (
        formState.contact_number === "" ||
        formState.contact_number === undefined
      ) {
        setErrors("Contact number is required");
        setLoader(false);
        return;
      }

      if (
        formState.finance_number === "" ||
        formState.finance_number === undefined
      ) {
        setErrorsFinace("Finance number is required");
        setLoader(false);
        return;
      }

      if (formState.contact_number) {
        const isValidContactNumber =
          formState.contact_number.length >= 4 &&
          formState.contact_number.length <= 15;

        if (!isValidContactNumber) {
          // You can set an error message or handle the validation error as needed
          // For now, let's log an error message to the console
          setErrors("Contact number must be between 6 and 15 characters");
          setLoader(false);
          return;
        }
      }

      if (formState.finance_number) {
        const isValidContactNumber =
          formState.finance_number.length >= 4 &&
          formState.finance_number.length <= 15;

        if (!isValidContactNumber) {
          // You can set an error message or handle the validation error as needed
          // For now, let's log an error message to the console
          setErrorsFinace("Finance number must be between 6 and 15 characters");
          setLoader(false);
          return;
        }
      }
      // Remove leading and trailing spaces before API call

      // const trimmedFormState = Object.fromEntries(
      //   Object.entries(formState).map(([key, value]) => [key, value.trim()])
      // );

      const trimmedFormState = Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      createGroup(trimmedFormState)
        .then((response) => {
          setFormState(initialFormState);
          setErrors("");
          setLoader(false);
          onHide();
          notifyTopRight(response.data.responseMessage);
          callgrouplist();
        })
        .catch((error) => {
          setLoader(false);
          setErrors("");
          // setApiError(error.response.data.message);
          notifyError(error.response?.data?.responseMessage);
        });
    }
  };

  const labelMappings = {
    finance_number: "Finance contact number",
  };

  return (
    <>
      <Modal className="modal fade" show={addHotelOwnerShow} size="xl">
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  {" "}
                  {editMode ? "Edit" : "Create"} Hotel Group
                </h4>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-dismiss="modal"
                  onClick={() => onHide()}
                >
                  <span>
                    {" "}
                    <strong>X</strong>{" "}
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  {requiredErrors && (
                    <div className="text-danger fs-12">{requiredErrors}</div>
                  )}
                  <div className="add-contact-content">
                    <div className="row">
                      {Object.keys(initialFormState).map((key) => {
                        if (
                          key === "country_code" ||
                          key === "finance_country_code"
                        ) {
                          return null;
                        }
                        return (
                          <div className="col-12 col-lg-6" key={key}>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                {labelMappings[key] ||
                                  key
                                    .replace(/_/g, " ")
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                              </label>
                              <div className="contact-name">
                                {key === "tax_number" ? (
                                  <input
                                    type="number"
                                    className="form-control"
                                    autoComplete="off"
                                    name={key}
                                    value={formState[key]}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key.replace(
                                      /_/g,
                                      " "
                                    )}`}
                                    min={0}
                                    required
                                  />
                                ) : key === "contact_email" ? (
                                  <input
                                    type="email"
                                    className="form-control"
                                    autoComplete="off"
                                    name={key}
                                    value={formState[key]}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key.replace(
                                      /_/g,
                                      " "
                                    )}`}
                                    required
                                  />
                                ) : key === "gst" ? (
                                  <Switch
                                    onChange={handleGst}
                                    checked={gst}
                                    className="react-switch"
                                  />
                                ) : key === "headquarter_address" ? (
                                  <GooglePlacesAutocomplete
                                    className="form-control"
                                    apiKey="AIzaSyA_kz_OxgMPwWS7AEWRVUHSLSvqYFlgZAc"
                                    selectProps={{
                                      placeholder: "Enter Address",

                                      // value: googleAddress,
                                      value: {
                                        label: googleAddress,
                                        value: googleAddress,
                                      },

                                      onChange: (place) => {
                                        handleAddress(place);
                                      },
                                    }}
                                  />
                                ) : key === "description" ? (
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name={key}
                                    rows={4}
                                    value={formState[key]}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key.replace(
                                      /_/g,
                                      " "
                                    )}`}
                                    required
                                  />
                                ) : key === "contact_number" ? (
                                  // <div className="d-flex align-items-center">
                                  //   <select
                                  //     onChange={handleCountryCodeChange}
                                  //     value={selectedCountryCode}
                                  //     className="form-control"
                                  //     style={{ width: "min-content" }}
                                  //     required
                                  //   >
                                  //     <option value="">Country code</option>
                                  //     {COUNTRIES.map((country, i) => (
                                  //       <option
                                  //         key={i}
                                  //         value={country.mobileCode}
                                  //       >
                                  //         {` ${country.mobileCode}`}
                                  //       </option>
                                  //     ))}
                                  //   </select>
                                  //   <input
                                  //     type="number"
                                  //     className="form-control"
                                  //     autoComplete="off"
                                  //     name={key}
                                  //     value={formState[key]}
                                  //     onChange={handleChange}
                                  //     placeholder={`Enter ${key.replace(
                                  //       /_/g,
                                  //       " "
                                  //     )}`}
                                  //     minLength={6}
                                  //     maxLength={15}
                                  //     required
                                  //   />
                                  // </div>
                                  <PhoneInput
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      fontSize: "16px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                    placeholder="Enter phone number"
                                    value={selectedCountryCode}
                                    onChange={handleCountryCodeChange}
                                    defaultCountry="US"
                                  />
                                ) : key === "finance_number" ? (
                                  <PhoneInput
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      fontSize: "16px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                    placeholder="Enter phone number"
                                    value={selectedFinanceCountryCode}
                                    onChange={handleFinanceCountryCodeChange}
                                    defaultCountry="US"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control"
                                    autoComplete="off"
                                    name={key}
                                    value={formState[key]}
                                    onChange={handleChange}
                                    placeholder={`Enter ${key.replace(
                                      /_/g,
                                      " "
                                    )}`}
                                    required
                                  />
                                )}
                              </div>
                              {key === "contact_number" && errors && (
                                <div className="text-danger fs-12">
                                  {errors}
                                </div>
                              )}

                              {key === "finance_number" && errorsFinace && (
                                <div className="text-danger fs-12">
                                  {errorsFinace}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  {editMode ? "Update" : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateHotelGroup;
