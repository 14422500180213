import React, { useEffect, useState } from "react";

import { Modal, Table } from "react-bootstrap";
import {
  assignHotelToHotelOwner,
  getAllHotelOwnerList,
} from "../../services/AuthService";
import { toast } from "react-toastify";

function HotelOwnerModal({ show, onHide, id }) {
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const notifyTopRight = (value) => {
    toast.success(`✅ ${value} !`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function getTableData() {
    setLoader(true);
    getAllHotelOwnerList()
      .then((response) => {
        setUsers(response.data.result);
        // setImage(response.data.data.Users?.fitnessCertificate);
        setLoader(false);
      })
      .catch((error) => {
        console.error(error, "error");
        setLoader(false);
      });
  }
  function assignHotelToHotelOwnerFunction(owner_id, hotel_id) {
    console.log(owner_id, hotel_id);
    assignHotelToHotelOwner({ owner_id, hotel_id })
      .then((response) => {
        notifyTopRight(response.data.responseMessage);
        onHide();
        // setUsers(response.data.result);
        // // setImage(response.data.data.Users?.fitnessCertificate);
        // setLoader(false);
      })
      .catch((error) => {
        notifyError(error.response.data.responseMessage);
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      <Modal className="modal fade" show={show} size="lg">
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Hotel owner list</h4>
              </div>

              <Modal.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>Hotel owner name </strong>
                      </th>

                      <th>
                        <strong>Email</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((item, i) => (
                      <tr
                        key={i}
                        className="hotel-owner-assign-data"
                        onClick={() =>
                          assignHotelToHotelOwnerFunction(item.id, id)
                        }
                      >
                        <td>
                          {item?.first_name}{" "}
                          {item.last_name.length > 4
                            ? item.last_name.slice(0, 4) + "..."
                            : item.last_name}
                        </td>

                        <td>
                          {item.email.length > 12
                            ? item.email.slice(0, 12) + "..."
                            : item.email}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {users?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                ) : (
                  ""
                )}
              </Modal.Body>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default HotelOwnerModal;
