import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  ADMIN_LIST,
  CATEGORY_LIST,
  CHANGE_PASSWORD,
  CREATE_CATEGORY,
  CREATE_GROUP,
  CREATE_HOTEL_OWNER,
  CREATE_PROPERTY,
  DASHBOARD,
  DELETE_ADMIN,
  DELETE_CATEGORY,
  DELETE_GROUP,
  DELETE_PROPERTY,
  EDIT_HOTEL_OWNER,
  GROUP_ID,
  GROUP_LIST,
  HOTEL_ASSIGN_TO_HOTEL_OWNER,
  HOTEL_OWNER_LIST,
  LOGIN,
  LOGOUT,
  PROPERTY_LIST,
} from "./AuthApiEndPoints";
import axiosInstance from "./Instance";
import instance from "./Instance";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axios.post(LOGIN, postData);
}

export function dashboard() {
  return axiosInstance.get(DASHBOARD);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function createGroup(formData) {
  return axiosInstance.post(CREATE_GROUP, formData);
}
export function forgetPassword(email) {
  const postData = {
    email,
  };

  return axios.post("https://api.hotelly.net/api/v1/admin/forgot", postData);
}

export function editGroup(formData, id) {
  return axiosInstance.put(CREATE_GROUP + `/${id}`, formData);
}
export function getAllGroup(currentPage, limit, search) {
  const keywordParam = search ? `&keyword=${search}` : "";

  return axiosInstance.get(
    GROUP_LIST + `?page=${currentPage}&perPage=${limit}${keywordParam}`
  );
}

export function createHotelOwner(
  email,
  first_name,
  last_name,
  country,
  time_zone,
  user_type,
  number,
  permission
) {
  const postData = {
    email,
    first_name,
    last_name,
    country_code: "+",
    country,
    time_zone,
    user_type,
    number,
    permission,
  };
  return axiosInstance.post(CREATE_HOTEL_OWNER, postData);
}

export function editHotelOwner(
  id,
  email,
  first_name,
  last_name,

  country,
  time_zone,
  user_type,
  number,
  permission
) {
  const postData = {
    email,
    first_name,
    last_name,
    country_code: "+",
    country,
    time_zone,
    user_type,
    number,
    permission,
  };
  return axiosInstance.put(EDIT_HOTEL_OWNER + `/${id}`, postData);
}
export function getAllAdmin(currentPage, limit, search, user_type) {
  console.log(currentPage, limit, search, user_type, "search api");
  const keywordParam = search ? `&keyword=${search}` : "";

  return axiosInstance.get(
    ADMIN_LIST +
      `?page=${currentPage}&perPage=${limit}${keywordParam}&user_type=${user_type}`
  );
}
export function deleteAdminAndOwner(id) {
  return axiosInstance.delete(DELETE_ADMIN + `/${id}`);
}

export function getAllProperty(currentPage, limit, search) {
  const keywordParam = search ? `&keyword=${search}` : "";

  return axiosInstance.get(
    PROPERTY_LIST + `?page=${currentPage}&perPage=${limit}${keywordParam}`
  );
}

export function getAllHotelOwnerList() {
  return axiosInstance.get(HOTEL_OWNER_LIST);
}

export function getGroupId() {
  return axiosInstance.get(GROUP_ID);
}
export function deleteGroup(id) {
  return axiosInstance.delete(DELETE_GROUP + `/${id}`);
}

export function deleteProperty(id) {
  return axiosInstance.delete(DELETE_PROPERTY + `/${id}`);
}
export function createProperty(formData) {
  return axiosInstance.post(CREATE_PROPERTY, formData);
}

export function assignHotelToHotelOwner(data) {
  return axiosInstance.post(HOTEL_ASSIGN_TO_HOTEL_OWNER, data);
}
export function editProperty(formData, id) {
  return axiosInstance.put(CREATE_PROPERTY + `/${id}`, formData);
}
export function logOut() {
  const data = localStorage.getItem("tokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(
    LOGOUT,
    { test: "" },
    {
      headers: myHeaders,
    }
  );
}

export function getAllCategoryList(currentPage, limit, search) {
  const keywordParam = search ? `&keyword=${search}` : "";

  return axiosInstance.get(
    CATEGORY_LIST + `?page=${currentPage}&perPage=${limit}${keywordParam}`
  );
}

export function deleteCategory(id) {
  return axiosInstance.delete(DELETE_CATEGORY + `?id=${id}`);
}

export function createCategory(name) {
  const postData = {
    name,
  };
  return axiosInstance.post(CREATE_CATEGORY, postData);
}
export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  localStorage.setItem("hotellySuperAdmin", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  //   const tokenDetailsString = localStorage.getItem("userDetails");
  //   let tokenDetails = "";
  //   if (!tokenDetailsString) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   tokenDetails = JSON.parse(tokenDetailsString);
  //   let expireDate = new Date(tokenDetails.expireDate);
  //   let todaysDate = new Date();
  //   if (todaysDate > expireDate) {
  //     dispatch(logout(history));
  //     return;
  //   }
  //   dispatch(loginConfirmedAction(tokenDetails));
  //   const timer = expireDate.getTime() - todaysDate.getTime();
  //   runLogoutTimer(dispatch, timer, history);
}
