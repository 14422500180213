import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { toast } from "react-toastify";

import CreateProperty from "../modal/CreateProperty";
import { deleteProperty, getAllProperty } from "../../services/AuthService";
import Delete from "../modal/Delete";
import ViewGroupDetail from "../modal/ViewGroupDetail";
import HotelOwnerModal from "../modal/HotelOwnerModal";

export default function PropertyListing(props) {
  const [addPropertyShow, setAddPropertyShow] = useState(false);
  console.log(addPropertyShow);
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTimer, setSearchTimer] = useState(null);
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [hotelOwnerModal, setHotelOwnerModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [id, setId] = useState("");
  const [propertyData, setPropertyData] = useState(null); // Store the data of the property being edited
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const handleEdit = (data) => {
    setPropertyData(data);
    setEditMode(true);
    setAddPropertyShow(true);
  };

  const handleCreate = () => {
    setPropertyData(null);
    setEditMode(false);
    setAddPropertyShow(true);
  };

  const handleModalClose = () => {
    setAddPropertyShow(false);
    setEditMode(false);
  };
  const limit = 10;

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getAllProperty(currentPage, limit, search)
      .then((response) => {
        setUsers(response.data.result.data);
        // setImage(response.data.data.Users?.fitnessCertificate);
        setLoader(false);
        const total = response.data.result.meta.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.error(error, "error");
        notifyError(error.response.data.responseMessage)
        setLoader(false);
      });
  }

  function onDelete() {
    setLoader(true);

    deleteProperty(id)
      .then((response) => {
        getTableData();
        setDeleteModal(false);
        setLoader(false);
        notifyTopRight(response.data.responseMessage);
      })
      .catch((error) => {
        setLoader(false);
        setDeleteModal(false);
        notifyError(error?.response?.data?.responseMessage);
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  useEffect(() => {
    if (search !== "") {
      setCurrentPage(0);
    }
  }, [search]);
  useEffect(() => {
    if (search !== "") {
      const delayedSearch = setTimeout(() => {
        setCurrentPage(0); // Reset currentPage to 0
        getTableData();
      }, 1000);

      return () => clearTimeout(delayedSearch);
    } else {
      setCurrentPage(0);
      getTableData();
    }
  }, [search]);
  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value.trim();
    setSearch(newSearchValue);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Property List" motherMenu="Property" />
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleCreate}
        >
          Create
        </button>
      </div>
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        // onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Name</strong>
                  </th>
                  <th>
                    <strong>Hotel Group</strong>
                  </th>
                  <th>
                    <strong>Hotel Address</strong>
                  </th>
                  <th>
                    <strong>Rooms</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.hotel_name.length > 12
                        ? item.hotel_name.slice(0, 12) + "..."
                        : item.hotel_name}
                    </td>
                    <td>{item.group_id}</td>
                    <td>
                      {item.address.length > 12
                        ? item.address.slice(0, 12) + "..."
                        : item.address}
                    </td>
                    <td>{item.room_count}</td>

                    {/* <td className="text-center">
                      {item.isApproved ? (
                        <Badge variant="success light">Approved</Badge>
                      ) : (
                        <Badge variant="danger light">Pending</Badge>
                      )}
                    </td>
                    <td className="text-center">
                      {item.isBlocked ? (
                        <Badge variant="danger light">Deactive</Badge>
                      ) : (
                        <Badge variant="success light">Active</Badge>
                      )}
                    </td> */}
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* {!item.isApproved && (
                            <Dropdown.Item onClick={() => onApprove(item._id)}>
                              Approve Profile
                            </Dropdown.Item>
                          )}

                          {item.isBlocked ? (
                            <Dropdown.Item onClick={() => onAction(item._id)}>
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onAction(item._id)}>
                              Disable
                            </Dropdown.Item>
                          )} */}
                          <Dropdown.Item onClick={() => handleEdit(item)}>
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setId(item.id);
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setViewDetailModal(true);
                              setDetail(item);
                            }}
                          >
                            View Detail
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setHotelOwnerModal(true);
                              setId(item.id);
                            }}
                          >
                            Assign with Hotel owner
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-end">
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      {loader && <Spinner />}
      {addPropertyShow && (
        <CreateProperty
          addPropertiesModelShow={addPropertyShow}
          onHide={handleModalClose}
          callHotelList={getTableData}
          editMode={editMode}
          propertyData={propertyData}
        />
      )}
      {deleteModal && (
        <Delete
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          callbackfunction={onDelete}
        />
      )}
      {viewDetailModal && detail && (
        <ViewGroupDetail
          show={viewDetailModal}
          onHide={() => setViewDetailModal(false)}
          data={detail}
        />
      )}

      {hotelOwnerModal && id && (
        <HotelOwnerModal
          show={hotelOwnerModal}
          onHide={() => setHotelOwnerModal(false)}
          id={id}
        />
      )}
    </div>
  );
}
